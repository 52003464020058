/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { get, last, compact } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Tabs } from "antd";

import { ContentBlock, TextBlock, TextBlockMedia } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { PureCarousel } from "../../components/PureReactCarousel";
import { ContentButtons } from "../../components/Buttons";
import { makeUrl, sorter, makeMediaData, makeNew, makeArticle, removePreloader, markdownConverter } from "../../helpers";
import { SERAFIM_PROJECT_CONTENT, SERAFIM_PROJECT_MAX_UPDATE } from "../../queries/queries.graphql";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import "./SerafimProject.scss";

export const query = graphql`
	query projectsDataQuery($slug: String!) {
		hasura {
			...SerafimProjectContent
		}
	}
`;

const MAKE_CONTENT_BY_TYPE_HASH = {
	news: makeNew,
	media_article: makeArticle,
};

export default function SerafimProject({ location, data }) {
	const { TabPane } = Tabs;

	const { theme } = useThemeContext();

	const [serafimProject, setSerafimProject] = useState(get(data, "hasura.serafim_projects[0]", {}));
	const [tabType, setTabType] = useState("content-tab-0");

	const maxUpdated = new Date(get(data, "hasura.serafim_projects[0].updated_at", new Date()));
	const currentPath = last(compact(get(location, "pathname", "").split("/")));

	const serafimProjectOptions = {
		variables: {
			location: currentPath,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const {
		loading: maxUpdateLoading,
		data: maxUpdateData,
		error: maxUpdateError,
	} = useQuery(SERAFIM_PROJECT_MAX_UPDATE, serafimProjectOptions);

	const [
		loadSerafimProject,
		{
			called: serafimProjectCalled,
			loading: serafimProjectLoading,
			error: serafimProjectError,
			data: serafimProjectData,
		},
	] = useLazyQuery(SERAFIM_PROJECT_CONTENT, serafimProjectOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_serafim_project_updated_at[0].updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadSerafimProject();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load SerafimProjectMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (serafimProjectData && !serafimProjectError) {
			setSerafimProject(get(serafimProjectData, "serafim_projects[0]", serafimProject));
			removePreloader(serafimProjectCalled);
		} else if (serafimProjectError) {
			console.error("Invalid load SerafimProjectData", { serafimProjectError });
			removePreloader();
		}
	}, [serafimProjectLoading]);

	const breadcrumbsData = {
		item: {
			title_full: "Гуманитарные проекты",
			slug: "serafim-project",
		},
	};

	const btnStyle = {
		padding: "20px 40px",
		textDecoration: "none",
		backgroundColor: theme.color.text || "#3f2512",
		color: theme.color.body || "#fff",
		display: "inline-block",
		marginBottom: "40px",
	};

	const page_title_full = get(serafimProject, "page_title_full", "");
	const content_blocks = get(serafimProject, "content_blocks", []);
	const main_image = get(serafimProject, "main_image", {});
	const settings = get(serafimProject, "settings", {});

	const news = get(serafimProject, "news_serafim_projects", []).map(item => item.news);
	const articles = get(serafimProject, "media_articles_serafim_projects", []).map(item => item.media_article);

	let newsAndArticles = {};
	let allNewsAndArticles = [...news, ...articles];

	allNewsAndArticles.sort((a, b) => sorter(+new Date(b.created_at), +new Date(a.created_at)));

	allNewsAndArticles.slice(0, 10).forEach(item => {
		// NOTE: materials union 2 types of entities, their field "id" can cross across the entities, so check id and type
		const key = `${item.id}-${item.type}`;

		newsAndArticles[key] = MAKE_CONTENT_BY_TYPE_HASH[item.type](item);
	});

	newsAndArticles = Object.values(newsAndArticles);

	const videos = makeMediaData(get(serafimProject, "serafim_projects_media_videos", []).map(item => item.media_video), "videos");
	const url = makeUrl.serafimProject(serafimProject);
	const imageSrc = get(main_image, "src", "");
	const updated_at = get(serafimProject, "updated_at", "");
	const project_link_title = get(settings, "project_link_title", "");

	return <Pages entity={serafimProject} url={url} >
		<ContentBlock key={"Breadcrumbs"}>
			<div className="container">
				<Breadcrumbs
					currentLabel={page_title_full}
					pageContext={breadcrumbsData}
				/>
			</div>
		</ContentBlock>
		<ContentBlock key={"media-content"}
			mount={content_blocks && !!content_blocks.length}
			className={"serafim-project-page"}
		>
			<div className={"container"}>
				<div className={"h1 text-page_title"}>
					<h1 itemProp="headline"
						style={{
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						}}
					>
						{page_title_full}
					</h1>
				</div>

				{project_link_title && (
					<a
						href={get(settings, "project_link", "")}
						style={btnStyle}
						target={"_blank"}
						rel="noopener noreferrer"
					>
						{project_link_title}
					</a>
				)
				}

				{content_blocks.length > 1
					?
					<Tabs
						type={"card"}
						defaultActiveKey={"0"}
						onChange={setTabType}
					>
						{
							content_blocks.map(({ title, body, media, type, buttons = [] }, idx) => {
								return (
									<TabPane
										key={`content-tab-${idx}`}
										tab={<span style={{ color: tabType !== `content-tab-${idx}` && theme.color.text }}>{title}</span>}
									>
										{ !!buttons.length && <ContentButtons items={buttons} /> }
										<TextBlockMedia
											items={media ? media.items : []}
											desc={markdownConverter(body)}
											left={idx % 2 !== 0}
											orientation={type}
											videoSrc={get(media, "video_src", "")}
											files={media ? media.files : []}
											date={updated_at}
										/>
									</TabPane>
								);
							})
						}
					</Tabs>
					:
					<Contents items={content_blocks} />
				}
			</div>
		</ContentBlock>
		<ContentBlock key={"news"} mount={newsAndArticles.length}>
			<div className={"container"}>
				<TextBlock
					title={"Новости и статьи проекта"}
					list={"Все материалы"}
					listLink={`/serafim-project/${get(serafimProject, "slug", "")}/materials`}
					allowBtn={true}
				>
					<PureCarousel
						type={"SlideNews"}
						items={newsAndArticles}
						mediaSize={["100%", 400]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 3,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 950,
									slidesPerView: 3,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>
		<ContentBlock key={"VideoGallery"} mount={videos.length}>
			<div className={"container"}>
				<TextBlock
					title={"Видео о проекте"}
					list={"Все видео"}
					id={"Serafim-school-all-videos"}
					listLink={`/serafim-project/${get(serafimProject, "slug", "")}/videos`}
					allowBtn={videos.length > 4}
				>
					<PureCarousel
						type={"SlideLinks"}
						items={videos}
						slideRatio={{
							naturalSlideWidth: 2,
							naturalSlideHeight: 1,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 1,
							naturalSlideHeight: 0.8,
						}}
						params={{
							slidesPerView: 2,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
							],
						}}
					/>
				</TextBlock>
			</div>
		</ContentBlock>
		<ContentBlock key={"share"} mount={!!url}>
			<div className={"container"}>
				<Share
					url={url}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</ContentBlock>
	</Pages>;
}

SerafimProject.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object,
};

SerafimProject.defaultProps = {
	data: {},
	pageContext: {},
	location: {},
};
